// Location: /themes/memoir/assets/js/components/Navigation.jsx
import React, { useState, useEffect } from 'react';

const Navigation = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentPath, setCurrentPath] = useState('/');

    useEffect(() => {
        setCurrentPath(window.location.pathname);
    }, []);

    const isActive = (path) => {
        if (path === '/') {
            return currentPath === '/';
        }
        return currentPath.startsWith(path);
    };

    const NavLink = ({ href, children, isButton = false }) => {
        const baseClasses = "transition-colors duration-200 font-medium text-base px-5 py-2.5 rounded-md";

        if (isButton) {
            return (
                <a
                    href={href}
                    className={`${baseClasses} bg-[#002147] text-white hover:bg-[#193761] shadow-sm md:ml-8`}
                >
                    {children}
                </a>
            );
        }

        return (
            <a
                href={href}
                className={`${baseClasses} ${
                    isActive(href)
                        ? 'bg-[#002147] text-amber-300'
                        : 'text-gray-700 hover:bg-gray-50'
                }`}
            >
                {children}
            </a>
        );
    };

    return (
        <nav className="bg-white border-b shadow-sm">
            <div className="max-w-6xl mx-auto px-4">
                <div className="flex items-center justify-between h-16">
                    <div className="flex items-center">
                    </div>
                    <div className="hidden md:flex items-center justify-center space-x-6">
                        <NavLink href="/">Home</NavLink>
                        <NavLink href="/gallery">Gallery</NavLink>
                        <NavLink href="/testimonials">Testimonials</NavLink>
                        <NavLink href="/about">About</NavLink>
                        <NavLink href="/contribute" isButton>Share a Memory</NavLink>
                    </div>
                    <div className="md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="text-gray-500 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#002147]"
                            aria-expanded={isOpen}
                            aria-controls="mobile-menu"
                        >
                            <span className="sr-only">Open main menu</span>
                            {!isOpen ? (
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                </svg>
                            ) : (
                                <svg className="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                </svg>
                            )}
                        </button>
                    </div>
                </div>
            </div>
            <div className={`md:hidden ${isOpen ? 'block' : 'hidden'}`} id="mobile-menu">
                <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                    <NavLink href="/">Home</NavLink>
                    <NavLink href="/gallery">Gallery</NavLink>
                    <NavLink href="/testimonials">Testimonials</NavLink>
                    <NavLink href="/about">About</NavLink>
                    <NavLink href="/contribute" isButton>Share a Memory</NavLink>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;

